import React, { FC, useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PurpleAccentHero from "../components/PurpleAccentHero"
import CookiesSnackbar from "../components/CookiesSnackbar"
import InternalButtonLink from "../components/InternalButtonLink"
import Stories from "../components/Stories"
import { useTypography } from "../styles/Typography"
import clsx from "clsx"
import { Colors } from "../utils/colors"
import { IStories } from "../types/types"
import { Api } from "../data/Api"
import {makeStyles} from "@material-ui/styles";
import { WORKSHOP } from "../utils/routes"
import DiscoverMoreBanner from "../components/DiscoverMoreBanner"

const useStyles = makeStyles({
    titleHeadingGradientWrapper: {
        width: "100%",
        height: "100%",
        background: "linear-gradient(110.11deg, #FFFFFF 38%, rgba(255, 255, 255, 0) 67.89%)",
        "@media(max-width: 959px)": {
            background: "rgba(255, 255, 255, 0) 67.89%)"
        }
    },
    titleHeadingText: {
        marginLeft: 100,
        maxWidth: 360,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100%",
        color: "rgba(0, 0, 0, 0.8)",
        letterSpacing: "0.25px",
        "@media (max-width: 959px)": {
            width: "100%",
            maxWidth: "100%",
            marginLeft: 0,
            padding: 24
        }
    },
    body: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 48,
        padding: 24
    },
    bodyContent: {
        textAlign: "center",
        width: "100%",
        maxWidth: 1200
    },
    container: {
        width: "100%",
        height: "100%",
        display: "grid",
        gridGap: 32,
        gridTemplateColumns: "repeat(auto-fit, 338px)",
        justifyContent: "center"
    },
    marginBottom: {
        marginBottom: "32px !important"
    },
    blankArticle: {
        width: 338,
        height: 338,
        padding: 24,
        background: "linear-gradient(0deg, #F3F1F8, #F3F1F8), #FFFFFF",
        borderRadius: "16px 0px 16px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    }
})


const storyComparator = (a:IStories,b:IStories) => {

    if( new Date(a.date) === new Date(b.date) ) {
        return a.id - b.id
    }

    return Number(new Date(b.date)) - Number(new Date(a.date))
}

const UserStoriesPage: FC = () => {
    const classes = useStyles()
    const typo = useTypography()
    const [stories, setStories] = useState<IStories[] | undefined>(undefined)

    const data = useStaticQuery(graphql`
        query {
            desktopHeroImage: file(relativePath: { eq: "studies.jpg" }) {
                childImageSharp {
                    fluid(quality: 75, maxWidth: 2160) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    useEffect(() => {
        const getData = async () => {
            const api = new Api()
            const items = await api.getStories()

            if (!items?.data) {
                return
            }
            setStories(items.data)
        }

        getData()
    }, [])

    return (
        <Layout>
            <SEO title="User Stories" />
            <DiscoverMoreBanner/>
            <PurpleAccentHero
                desktopImage={data.desktopHeroImage.childImageSharp.fluid}
                mobileImage={data.desktopHeroImage.childImageSharp.fluid}
                alt="Man smiling in front of his computer in an open office environment"
            >
                <div className={classes.titleHeadingGradientWrapper}>
                    <section className={classes.titleHeadingText}>
                        <h1 className={clsx(typo.h4, classes.marginBottom)}>
                            Our partners and customers are unlocking the value of Microsoft 365 in the way that works
                            for them
                        </h1>
                        <p className={clsx(typo.subtitle1, classes.marginBottom)}>
                            See how Nulia Works can unlock the value of Microsoft 365 for your organization
                        </p>
                        <InternalButtonLink variant="orange" href={WORKSHOP} style={{ zIndex: 1 }}>
                            Free Workshop
                        </InternalButtonLink>
                    </section>
                </div>
            </PurpleAccentHero>
            <section className={classes.body}>
                <div className={classes.bodyContent}>
                    <h2 className={typo.h5} style={{ marginBottom: 32 }}>
                        Our users are having a great experience with Nulia Works and are seeing significant results
                    </h2>
                    <div className={classes.container}>
                        {stories?.sort(storyComparator).map((value, index) => (
                            <Stories
                                key={index}
                                index={index}
                                name={value.name}
                                logo={value.img}
                                description={value.body}
                                pdf={value.pdf}
                                video={value.video}
                            />
                        ))}
                        <article className={classes.blankArticle}>
                            <p className={typo.h5} style={{ color: Colors.purple, textAlign: "left" }}>
                                More user stories coming soon...
                            </p>
                        </article>
                    </div>
                </div>
            </section>
            <CookiesSnackbar />
        </Layout>
    )
}

export default UserStoriesPage
