import React, { FC, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Colors } from "../utils/colors"
import { useTypography } from "../styles/Typography"
import { Button, Fade } from "@material-ui/core"
import { NEWS_PATH, VIDEOS_PATH } from "../utils/constants"
import isMobile from "is-mobile"

const useStyles = makeStyles({
    root: {
        width: 338,
        height: 338
    },
    outerCard: {
        width: 338,
        height: 338,
        padding: 16,
        backgroundColor: Colors.purple,
        borderRadius: "16px 0px 16px 0px",
        position: "absolute"
    },
    card: {
        backgroundColor: Colors.white,
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 12,
        borderRadius: "16px 0px 16px 0px"
    },
    tab: {
        width: 100,
        height: 112,
        backgroundColor: Colors.white,
        position: "absolute",
        bottom: 0,
        borderRadius: "16px 16px 0px 0px",
        boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.08)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    icon: {
        width: 40,
        height: 40
    },
    image: {
        maxWidth: 250
    }
})

interface IProps {
    index: number
    name: string
    logo: string
    description: string
    pdf?: string
    video?: string
}

const Stories: FC<IProps> = ({ index, name, logo, description, pdf, video }) => {
    const classes = useStyles()
    const typo = useTypography()
    const [showBack, setShowBack] = useState<boolean>(false)

    const flip = () => {
        setShowBack(!showBack)
    }

    const onMouseEnter = () => {
        setShowBack(true)
    }

    const onMouseLeave = () => {
        setShowBack(false)
    }

    const openPdf = (url: string) => {
        window.open(`${NEWS_PATH}${url}`, "_blank")
    }

    const openVideo = (url: string) => {
        window.open(`${VIDEOS_PATH}${url}`, "_blank")
    }

    return (
        <article
            className={classes.root}
            onClick={flip}
            onMouseEnter={!isMobile() ? onMouseEnter : undefined}
            onMouseLeave={!isMobile() ? onMouseLeave : undefined}
        >
            <Fade in={!showBack} timeout={!showBack ? 800 : 0}>
                <div className={classes.outerCard}>
                    <div className={classes.card}>
                        <img className={classes.image} src={`${NEWS_PATH}${logo}`} alt={name} loading="lazy" />
                        {/*<div className={classes.tab}>*/}
                        {/*    <Img className={classes.icon} fluid={data.teams.childImageSharp.fluid} />*/}
                        {/*    <Pace pace={EPace.Fast} />*/}
                        {/*    <p className={typo.h6}>700%</p>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </Fade>
            <Fade in={showBack} timeout={showBack ? 800 : 0}>
                <div className={classes.outerCard} style={{ backgroundColor: Colors.lightPurple }}>
                    <div className={classes.card}>
                        <p className={typo.caption} style={{ textAlign: "left" }}>
                            {description}
                        </p>
                        {pdf && (
                            <Button
                                className={typo.button}
                                style={{ color: Colors.purple }}
                                onClick={() => openPdf(pdf)}
                            >
                                READ MORE (PDF)
                            </Button>
                        )}
                        {video && (
                            <Button
                                className={typo.button}
                                style={{ color: Colors.purple }}
                                onClick={() => openVideo(video)}
                            >
                                VIEW MORE (MP4)
                            </Button>
                        )}
                    </div>
                </div>
            </Fade>
        </article>
    )
}

export default Stories
