import { API_URL } from "../utils/constants"
import axios from "axios"
import { INews, IResponse, IStories } from "../types/types"

export class Api {
    public get = async (url: string) => {
        try {
            const response = await axios.get(url)
            if (!response) {
                return
            } else {
                return response.data
            }
        } catch (error) {
            console.error(error)
            return
        }
    }

    public getNews = async (): Promise<IResponse<INews[]> | undefined> => {
        const url = `${API_URL}/News`

        return await this.get(url)
    }

    public getStories = async (): Promise<IResponse<IStories[]> | undefined> => {
        const url = `${API_URL}/Stories`

        return await this.get(url)
    }
}
