import { makeStyles } from "@material-ui/core/styles"
import { Colors } from "../utils/colors"

export const useTypography = makeStyles((theme) => ({
    h1: {
        fontFamily: "Ubuntu",
        fontSize: 96,
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "104px",
        letterSpacing: -1.5,
        color: Colors.textPrimary,
        margin: 0
    },
    h2: {
        fontFamily: "Ubuntu",
        fontSize: 60,
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "64px",
        letterSpacing: -0.5,
        color: Colors.textPrimary,
        margin: 0
    },
    h3: {
        fontFamily: "Ubuntu",
        fontSize: 48,
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "56px",
        color: Colors.textPrimary,
        margin: 0
    },
    h4: {
        fontFamily: "Ubuntu",
        fontSize: 32,
        fontWeight: 600,
        fontStyle: "normal",
        lineHeight: "40px",
        letterSpacing: 0.25,
        color: Colors.textPrimary,
        margin: 0
    },
    h5: {
        fontFamily: "Ubuntu",
        fontSize: 24,
        fontWeight: 600,
        fontStyle: "normal",
        lineHeight: "28px",
        letterSpacing: "normal",
        color: Colors.textPrimary,
        margin: 0
    },
    h6: {
        fontFamily: "Ubuntu",
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: 20,
        lineHeight: "24px",
        letterSpacing: 0.25,
        color: Colors.textPrimary,
        margin: 0
    },
    body1: {
        fontFamily: "Open Sans",
        fontSize: 16,
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "24px",
        letterSpacing: 0,
        color: Colors.textSecondary,
        margin: 0
    },
    body2: {
        fontFamily: "Open Sans",
        fontSize: 14,
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "21px",
        letterSpacing: 0.25,
        color: Colors.textSecondary,
        margin: 0
    },
    subtitle1: {
        fontFamily: "Ubuntu",
        fontSize: 16,
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "24px",
        letterSpacing: 0.15,
        color: Colors.textPrimary,
        margin: 0
    },
    subtitle2: {
        fontFamily: "Ubuntu",
        fontSize: 14,
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "24px",
        letterSpacing: 0.1,
        color: Colors.textPrimary,
        margin: 0
    },
    overline: {
        fontFamily: "Ubuntu",
        fontSize: 12,
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "16px",
        letterSpacing: 2,
        color: Colors.textSecondary,
        textTransform: "uppercase",
        margin: 0
    },
    overlineMini: {
        fontFamily: "Ubuntu",
        fontSize: 10,
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "40px",
        letterSpacing: 1.25,
        color: Colors.textSecondary,
        textTransform: "uppercase",
        margin: 0
    },
    caption: {
        fontFamily: "Open Sans",
        fontSize: 12,
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: 0.5,
        color: Colors.textSecondary,
        margin: 0
    },
    label: {
        fontFamily: "Open Sans",
        fontSize: 10,
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "20px",
        letterSpacing: 0.5,
        color: Colors.textSecondary,
        margin: 0
    },
    button: {
        fontFamily: "Ubuntu",
        fontSize: 10,
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "40px",
        letterSpacing: 1.25,
        color: Colors.textSecondary,
        margin: 0
    }
}))
